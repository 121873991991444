import AccountSettings from 'components/pages/AccountSettings'
import React from 'react'
import Layout from '../layouts/layout'

const HomePageWrapper = () => {
  if (typeof window === "undefined") return null

  return <Layout>
    <AccountSettings />
  </Layout>
}

export default HomePageWrapper
